<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2021-12-14 14:59:11
 * @LastEditors: Please set LastEditors
 * @Description: 新闻动态详情
 * @FilePath: \new-website-1123\src\views\service\joinus\index.vue
-->
<template>
  <div class="news-details">
    <wTitle class="title" model="资讯" :isDetails="false" modelkey="详情"></wTitle>
    <div class="detailsInfo">
      <h2>{{currentInfo.name}}</h2>
      <div class="basis">
         <div class="origin"> <span>来源： {{ currentInfo.source }}<span class="link" @click="openUrl(currentInfo.sourceUrl)">{{ currentInfo.sourceUrl }}</span> </span></div>

         <span class="now"> <span>来源： {{ currentInfo.source }}<span class="link" @click="openUrl(currentInfo.sourceUrl)">{{ currentInfo.sourceUrl }}</span> </span></span>


          <span>时间：{{ currentInfo.createTime }}</span>
          <span style="margin-left: 5px;">浏览量：{{ currentInfo.views+1 }}</span>
      </div>
      <div class="info" v-html="clearInlineStyle(currentInfo.info)"></div>
    </div>
    <div class="nextNewsInfo">
        <div class="last" @click="lastNews">上一篇：{{ lastInfoTitle?lastInfoTitle:'已经是第一篇了' }}</div>
        <div class="" @click="nextNews">下一篇：{{ nextInfoTitle?nextInfoTitle:'已经是最后一篇了' }}</div>
    </div>
    <div class="back" @click="goNewsBack">
      <span class="iconfont iconxia " ></span>
      <span>返回列表</span>
    </div>
  </div>
</template>

<script>
import {getNesListApi,getNesViewApi} from '@/api/index.js'
import wTitle from "@/components/sTitle/viewtitle";
export default {
  name: "news-details",
  components: {wTitle},
  data() {
    return {
      newsList:[],
      currentInfo:{},
      lastInfoTitle:'',
      nextInfoTitle:'',
      regex : /^(?:https?|ftp):\/\/[^\s]+$/i,
      prePath:''
    };
  },

  created() {},
  computed: {

  },
  mounted() {
    this.getCurrentInfo()
    this.newsViews()
    this.beforeRouteEnter
  },

  beforeRouteEnter(to, from, next)  {
    next(vm => {
      vm.prePath = from.path
    })
          next()
      },
  methods: {

    goNewsBack(){
      if(this.prePath == '/news/details'){
        this.$router.push('/news/home')
      }else {
        this.$router.back();
      }
    },
    getCurrentInfo(){
      getNesListApi({pageSize:999,status:1}).then(res=>{
        this.currentInfo = res.data.data.list.filter(el=> el.id == this.$route.query.id)[0]
        if(!this.currentInfo)return
        this.newsList = res.data.data.list.filter(el=> el.type == this.currentInfo.type)
        this.lastInfoTitle = this.newsList[this.newsList.findIndex(el=> el.id == this.$route.query.id)-1]?.name
        this.nextInfoTitle = this.newsList[this.newsList.findIndex(el=> el.id == this.$route.query.id)+1]?.name
      })
    },
    openUrl (openurl)  {
        let url = openurl
                  if (!this.regex.test(url)) {
                      // 没有完整的前缀, 进行自动补全
                      if (!url.match(/^((https?:\/\/)|ftp:\/\/)/i)) {
                          url = `http://${url}`; // 自动补全
                           window.open(url);
                            }
                }else{
                    window.open(url);
                }
    },
    lastNews(){
      let index = this.newsList.findIndex(el=> el.id == this.$route.query.id)
      if(index == 0){
        this.$message({
          message: '已经是第一篇了',
          type: 'warning',
          duration: 1000

        });
        return
      }
      this.$router.push({path:'/news/details',query:{id:this.newsList[index-1].id}})
     this.getCurrentInfo()
     this.newsViews()
    },
    nextNews(){
      let index = this.newsList.findIndex(el=> el.id == this.$route.query.id)
      if(index == this.newsList.length-1){
        this.$message({
          message: '已经是最后一篇了',
          type: 'warning',
          duration: 1000
        });
        return
      }
      this.$router.push({path:'/news/details',query:{id:this.newsList[index+1].id}})
      this.getCurrentInfo()
      this.newsViews()
    },
    newsViews(){
      getNesViewApi({pid:this.$route.query.id,type:3}).then(res=>{
      })
    },

     clearInlineStyle(htmlString) {
  // 创建一个临时元素，用于转换 HTML 字符串为 DOM 元素
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // 查找包含 style 属性的所有元素，并移除它们的 width 和 height 样式
  const elements = tempElement.querySelectorAll('[style]');
  elements.forEach(element => {
    const styles = element.getAttribute('style').split(';');
    const newStyles = [];
    for (let i = 0; i < styles.length; i++) {
      const style = styles[i].trim();
      if (style !== '' && !style.startsWith('width') && !style.startsWith('height')) {
        newStyles.push(style);
      }
    }
    element.setAttribute('style', newStyles.join(';'));
  });

  // 查找包含 width 和 height 属性的所有元素，并移除这些属性
  const sizes = tempElement.querySelectorAll('[width], [height]');
  sizes.forEach(element => {
    element.removeAttribute('width');
    element.removeAttribute('height');
  });

  // 返回更新后的 HTML 字符串
  return tempElement.innerHTML;
}

  },
};
</script>

<style lang="scss" scoped>

.news-details{
  padding: 0 18.75VW;
  color: #fff;
  line-height:1.5;
.title {
  margin-top: 80.0006px;
}
.detailsInfo {
  width: 100%;
  background-color: #1d1d1d;
  padding: 40px;
  h2 {
    font-size: 44.0006px;
  }
  .basis {
    margin-top: 24px;
    color: #999999;
    span:nth-child(2) {
      margin: 0  8px;
    }
    .origin {
    display: none;
  }
    div {
      width: 100%;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .link {
      width: 100%;
      color: #1DBF73;
      cursor: pointer;
      margin-left: 4.9997px;
    }

  }
  .info {
    margin-top: 48px;
    font-size: 15.9994px;
    max-width: auto !important;
    &>>> table{
      width: 100%;
    }


  }
  ::v-deep img,tabel {
      max-width: 100%;
      height: auto !important;
}

}
.nextNewsInfo {
  margin-top: 80.0006px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  div {
    width: 30.4vw;
    padding: 18px 0 16.9997px 24px;
    background-color: #1d1d1d;
    font-size: 14.0006px;
    &:hover {
     background-color: #172820;
     color: #1DBF73;
     cursor: pointer;
 }
  }
  div:nth-child(1) {
    margin-right: 32.0006px;

  }

}
.back {
  margin: 32.0006px 0 80.0006px 0;
  width: 100%;
  height: 54px;
  background-color: #1d1d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #172820;
    color: #1DBF73;
    cursor: pointer;
  }
  span:nth-child(1) {
    display: block;
    transform: rotate(90deg) !important;
    margin-right: 8.0006px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  span {
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
}
}
@media only screen and (max-width: $mobile-width) {
  .news-details{
  padding: 0 0.5rem;
  color: #fff;
  line-height:1.5;

.detailsInfo {
  padding: 0.2rem;
  h2 {
    font-size: .5167rem;
  }
 .basis {
  .origin {
    display: block;
  }
  .now {
    display: none;
  }
  span:nth-child(2) {
      margin: 0 ;
    }
 }

  }
  .info {
    font-size: .6666rem;

  }


}
.nextNewsInfo {
  margin-top: 0.3rem !important;
  div {
    padding: 0.1rem 0 0.1rem 0.1rem !important;
    font-size: .5834rem;
    max-height: .8834rem;
    height: .5834rem;
    overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
  }



}
.back {
    height: .5834rem !important;
    margin-bottom: 0.3rem !important;
    margin-top: 0.3rem !important;
    .iconfont {
      font-size: .18rem !important;
    }

}
}
</style>
